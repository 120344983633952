.footer{
    margin-top: auto;
    width: 100%;
    overflow: hidden;
    &__pagetop{
        position: relative;
        &__link{
            position: fixed;
            left: 50%;
            bottom: 3rem;
            transform: translateX(-100%);
            background-color: #fff;
            font-size: 1.4rem;
            font-weight: bold;
            width: 8rem;
            height: 8rem;
            border-radius: 4rem;
            transition: opacity .5s ease-in;
            opacity: 0;
            &.show{
                opacity: 1;
            }
            &:hover{
                border-bottom: none;
            }
            .icon{
                margin: 0 auto .5rem;
                transform: rotate(270deg);
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
}