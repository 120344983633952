@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}

.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}

.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}

.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}

.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}

.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}

.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}

.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}

.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-close:before {
  transform: rotate(-45deg);
}

.modaal-close:after {
  transform: rotate(45deg);
}

.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}

.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}

.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}

.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}

.modaal-gallery-item img {
  display: block;
}

.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}

.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}

.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}

.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}

.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

/*
このサイトでのみ使用したいmixinはここへ記述してください
*/
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
  width: 100%;
  overflow: hidden;
}

.footer__pagetop {
  position: relative;
}

.footer__pagetop__link {
  position: fixed;
  left: 50%;
  bottom: 3rem;
  transform: translateX(-100%);
  background-color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  width: 8rem;
  height: 8rem;
  border-radius: 4rem;
  transition: opacity .5s ease-in;
  opacity: 0;
}

.footer__pagetop__link.show {
  opacity: 1;
}

.footer__pagetop__link:hover {
  border-bottom: none;
}

.footer__pagetop__link .icon {
  margin: 0 auto .5rem;
  transform: rotate(270deg);
  width: 1.6rem;
  height: 1.6rem;
}

html {
  font-family: "Arial","Meiryo","MS PGothic","Hiragino Kaku Gothic ProN","Osaka",sans-serif;
  font-size: 10px;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  color: #3d3d3d;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  box-sizing: border-box;
}

@media screen and (min-width: 985px) {
  body {
    overflow-x: hidden;
  }
}

a {
  text-decoration: underline;
  color: #0473C6;
}

a:visited {
  color: #A342C1;
}

a:hover {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (min-width: 641px) {
  .only-sp {
    display: none;
  }
}

@media (max-width: 640px) {
  .only-pc {
    display: none;
  }
}

img {
  width: 100%;
  height: auto;
}

ol, li {
  list-style: none;
}

.header {
  padding: 10px 32px 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  z-index: 10;
  background-color: #fff;
  align-items: center;
  width: 100%;
  z-index: 99;
}

.header.-simple {
  height: 70px;
}

.header__logo {
  font-size: 18px;
  font-weight: bold;
}

.header__logo a {
  color: #3157BC;
  text-decoration: none;
}

.header-info {
  display: flex;
  align-items: center;
}

.header-info__tit {
  margin-left: 20px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 12px;
  border-left: 1px solid #CCDADB;
}

.header-info .tel {
  line-height: 1;
  margin-left: 15px;
  padding: 6px 15px 6px 8px;
  border-radius: 4px;
  border: 1px solid #CCDADB;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-info .tel__img {
  width: 18px;
  margin-right: 10px;
}

.header-info .tel__num {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 4px;
}

.header-info .tel__desc {
  font-size: 12px;
  border-left: 1px solid #CCDADB;
}

.header-info .tel__link:before {
  content: '';
  background: url(/assets/img/cmn_icon_arrow-s.svg) center left/cover;
  width: 4px;
  height: 6px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: 2px;
}

.header-info .tel__content {
  padding-right: 14px;
  margin-right: 14px;
  border-right: #CCDADB 1px solid;
}

.sidenav {
  width: 200px;
  background-color: #F4F7FA;
  padding: 15px 10px 0;
  line-height: 20px;
  position: fixed;
  min-height: 100vh;
  z-index: 98;
}

.sidenav__mov {
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444;
  text-decoration: none;
  line-height: 16px;
}

.sidenav-list {
  border-bottom: 1px solid #D2E1EB;
  font-weight: bold;
  height: 60px;
}

.sidenav-list a {
  color: #444444;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 10px;
}

.sidenav-list__icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sidenav-list:last-of-type {
  border: none;
}

.sidenav-list__txt {
  color: #3157BC;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-width: 1000px;
  margin-top: 70px;
}

.container.-login {
  min-width: 900px;
}

.main__wrapper {
  width: calc(100% - 200px);
  margin-left: 200px;
}

.main {
  padding: 0 0 100px;
  min-width: 800px;
}

.breadcrumb + .section {
  padding: 10px 30px 100px;
}

.section {
  margin: 100px 0;
  padding: 0 30px;
}

.section:last-child {
  margin-bottom: 0;
}

.section.-bgblue {
  background-color: #F4F9FC;
  margin-left: 0;
  margin-right: 0;
}

.section__tit {
  font-size: 32px;
  text-align: center;
  color: #3157BC;
  margin-bottom: 50px;
}

.section__tit:after {
  content: "";
  height: 5px;
  width: 50px;
  background: #3157BC;
  display: block;
  margin: 20px auto 0;
}

.section__heading {
  padding: 20px 0;
  font-size: 20px;
  border-top: 3px solid #3157BC;
  border-bottom: 1px solid #3157BC;
  color: #3157BC;
  text-align: center;
  margin-bottom: 50px;
}

.section__flexcolumn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section__flexcolumn.-center {
  justify-content: center;
}

.section__gridcolumn {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 55px;
}

.section__card {
  border: 1px solid #CCDADB;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.section__card__tit {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.section__card:nth-of-type(2) .guide__card__img {
  height: 64px;
  width: auto;
}

.section__hr {
  border-bottom: 1px solid #EAEAEA;
  border-top: none;
  border-right: none;
  border-left: none;
  margin: 20px 0;
}

.section__subheading {
  font-size: 20px;
  font-weight: bold;
  margin: 50px 0 20px;
}

.section__subheading.in-box {
  font-size: 16px;
  margin: 0 0 20px;
}

.section__subheading.icon img {
  width: 20px;
  margin-right: 8px;
  vertical-align: top;
}

.section__subheading.border-top {
  border-top: 1px solid #EAEAEA;
  padding-top: 40px;
}

.section__txt {
  margin-bottom: 30px;
}

.section__txt.-center {
  text-align: center;
}

.section__txt.-list {
  line-height: 1.8;
}

.section__attention {
  padding: 0 20px 20px 20px;
}

.section__attention-txt {
  font-size: 12px;
}

.section__button {
  display: flex;
  justify-content: center;
  margin: 30px 0 50px;
}

.section__button.net-framework {
  margin: 30px 0 0;
}

.section__button a.-external:before {
  content: '';
  background: url(/assets/img/cmn_icon_popwin-white.svg) center left/cover;
  width: 14px;
  height: 11px;
  display: inline-block;
  position: absolute;
  right: 40px;
}

.section__box-line {
  border: #CCDADB solid 1px;
  border-radius: 4px;
  padding: 30px 40px;
}

.section__add-group dl {
  margin: 30px 0 20px;
}

.heading {
  padding: 0 30px 0 30px;
}

.heading__tit {
  font-size: 40px;
  color: #3157BC;
  margin-bottom: 32px;
  line-height: 1.3;
}

.heading__tit.-wrap {
  white-space: nowrap;
}

.heading__tit span {
  font-size: 32px;
}

.heading__tit.-fixed {
  padding: 132px 30px 0 30px;
}

.heading__desc {
  font-size: 16px;
}

.heading.-single {
  margin: 0 0 100px;
  padding: 0;
}

.heading__complete {
  display: flex;
  justify-content: space-between;
  padding: 16px 56px 16px 40px;
  align-items: center;
  background-color: #F4F9FC;
  margin: 0px 0 50px;
}

.heading__complete b {
  color: #C94701;
  font-weight: bold;
  font-size: 20px;
}

.heading__list {
  margin-top: 20px;
}

.breadcrumb {
  padding: 32px 30px;
}

.breadcrumb__list {
  font-size: 12px;
  display: inline-block;
}

.breadcrumb__list a:after {
  content: '';
  width: 6px;
  height: 6px;
  border-top: 1px solid #444;
  border-right: 1px solid #444;
  transform: rotate(45deg);
  display: inline-block;
  margin: 0 8px;
  vertical-align: 1px;
}

.txt-bold {
  font-weight: bold;
}

.txt-attention {
  color: #c94701;
}

.txt-form-att {
  font-size: 12px;
}

.txt-goiken {
  line-height: 3;
}

.txt-center {
  text-align: center;
}

.txt-align-middele {
  vertical-align: middle;
}

.txt-small {
  font-size: 12px;
}

.txt-dia {
  font-size: 12px;
  vertical-align: middle;
  margin-right: 2px;
}

.txt-margin {
  display: block;
  height: 10px;
}

.txt-middle {
  vertical-align: middle;
}

.txt-asterisk {
  font-size: 12px;
  color: #444;
}

.txt-form-error {
  margin: 0 0 20px;
  color: #C94701;
}

.txt-login-error {
  margin: 0 0 10px;
  color: #C94701;
}

.txt-attention-confirm {
  color: #C94701;
  font-size: 16px;
  margin-top: 15px;
}

.txt-note {
  display: flex;
  align-items: center;
}

.txt-note.-top {
  align-items: flex-start;
}

.txt-note .asterisk {
  font-size: 12px;
  margin-right: 4px;
}

.txt-blue {
  color: #3157BC;
}

.button {
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.button.-prime {
  background-color: #3157BC;
  box-shadow: 0 2px 0 #000E5C;
  color: #fff;
  padding: 22px 0;
}

.button.-prime.-center {
  width: 390px;
}

.button.-prime:hover {
  background-color: #2D61EA;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-prime.guide13 {
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  padding: 11px 0  6px;
}

.button.-prime.guide0 {
  transform: translateX(-32.5px);
}

.button.-main {
  background-color: #3157BC;
  box-shadow: 0 2px 0 #000E5C;
  color: #fff;
  padding: 22px 0;
  width: 360px;
}

.button.-main:hover {
  background-color: #2D61EA;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-main {
  background-color: #F5E06B;
  box-shadow: 0 2px 0 #B1923E;
  color: #fff;
  padding: 22px 0;
  width: 360px;
}

.button.-main:hover {
  background-color: #FFED86;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-third {
  background-color: #F4F9FC;
  box-shadow: 0 2px 0 #B7C9CB;
  border: 1px solid #CCDADB;
  color: #444;
  padding: 22px 0;
  width: 360px;
  height: 60px;
  border: transparent;
}

.button.-third:hover {
  background-color: #DEEDF5;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-second {
  background-color: #F5E06B;
  box-shadow: 0 2px 0 #B1923E;
  color: #444444;
  padding: 22px 0;
  font-size: 15px;
}

.button.-second:hover {
  background-color: #FFED86;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-second:after {
  border-top: 2px solid #444444;
  border-right: 2px solid #444444;
}

.button.-second.guide13 {
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  padding: 11px 0  6px;
}

.button.-second.modal {
  padding: 12px 0;
  width: 360px;
  display: block;
}

.button.-second.modal.-first {
  height: 75px;
  line-height: 1.6;
}

.button.-second.-hero {
  height: 40px;
}

.button.-second.guide10 {
  height: 60px;
}

.button.-window {
  border: none;
}

.button.-h60 {
  height: 60px;
}

.button.-third {
  background-color: #F4F9FC;
  box-shadow: 0 2px 0 #B7C9CB;
  color: #444444;
  padding: 22px 0;
  font-size: 16px;
}

.button.-third:hover {
  background-color: #DEEDF5;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button.-third:after {
  border-top: 2px solid #444444;
  border-right: 2px solid #444444;
}

.button.-third.-column {
  padding: 12px 0;
}

.button.-login {
  background-color: #3157BC;
  box-shadow: 0 2px 0 #000E5C;
  color: #fff;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
}

.button.-login:hover {
  background-color: #2D61EA;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.button:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.button.-center {
  margin: 0 auto;
}

.button .-popwin-w {
  display: block;
  position: relative;
  padding: 0 25px 0 0;
}

.button .-popwin-w::after {
  content: '';
  display: block;
  position: absolute;
  top: 0.1em;
  right: 0;
  width: 15px;
  height: 12px;
  background-repeat: no-repeat;
  background-image: url("../img/cmn_icon_popwin-w.svg");
}

.morebtn {
  border: 1px solid #3157BC;
  color: #3157BC;
  border-radius: 40px;
  padding: 10px 60px;
  display: inline-block;
  margin-top: 54px;
  text-decoration: none;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.morebtn:hover {
  background-color: #EAF4FF;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.morebtn:after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #3157BC;
  border-right: 2px solid #3157BC;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
}

.morebtn.-close {
  padding: 18px 65px;
}

.morebtn.-close::after {
  display: none;
}

.morebtn:visited {
  color: #3157BC;
}

.footer-btn {
  padding: 10px;
  display: block;
  border: 1px solid #3157BC;
  border-radius: 50px;
  text-decoration: none;
  width: 48%;
  position: relative;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3157BC;
  flex-wrap: wrap;
}

.footer-btn:hover {
  background-color: #EAF4FF;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.footer-btn:visited {
  color: #3157BC;
}

.footer-btn__num {
  font-size: 12px;
  display: block;
  width: 100%;
}

.footer-btn__small-txt {
  font-size: 12px;
}

.footer-btn:after {
  content: '';
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  transform: rotate(45deg);
  border-top: 2px solid #3157BC;
  border-right: 2px solid #3157BC;
}

.footer-btn.-next:after {
  top: calc(50% - 6px);
  right: 12px;
}

.footer-btn.-back:after {
  top: calc(50% - 6px);
  left: 12px;
  transform: rotate(225deg);
}

.footer-btn__wrapper {
  padding: 100px 0 0;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-btn__wrapper.-center {
  justify-content: center;
}

.footer-btn__wrapper.-complete {
  padding: 50px 0 0 0;
  justify-content: center;
}

.significant {
  background-color: #FFF8F2;
  padding: 22px 30px;
  display: flex;
  line-height: 1;
}

.significant__icon {
  width: 16px;
  vertical-align: -2px;
  margin-right: 10px;
}

.significant__tit {
  color: #C94701;
  font-weight: bold;
  margin-right: 30px;
}

.significant__desc:before {
  content: '';
  background: url(/assets/img/cmn_icon_arrow-s.svg) center left/cover;
  width: 4px;
  height: 6px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: 2px;
}

.hero {
  padding: 53px 30px 40px;
  background: url(/assets/img/top_mv@2x.png) top center/auto 100% no-repeat;
}

@media screen and (min-width: 1920px) {
  .hero {
    background: url(/assets/img/top_mv@2x.png) top right/auto 100% no-repeat;
  }
}

.hero__lead {
  font-size: 28px;
  line-height: 36px;
  color: #3157BC;
  margin-bottom: 33px;
}

.hero__lead.-shoulder {
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1;
  display: block;
}

.hero__primecta {
  width: 390px;
}

.hero__secondcta {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 390px;
  margin-top: 10px;
}

.hero__secondcta .-second {
  width: 190px;
}

.hero__attention {
  font-size: 12px;
  color: #444;
  width: 264px;
  margin-top: 38px;
  line-height: 16px;
}

.merit__flex {
  width: 31%;
}

.merit__list {
  text-align: center;
}

.merit__img {
  width: 191px;
  margin: 0 auto;
}

.merit__tit {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
}

.merit__desc {
  text-align: left;
  min-height: 100px;
  max-width: 360px;
  margin: 0 auto;
}

.info__list {
  display: flex;
  padding: 24px 10px;
  border-top: 1px solid #EAEAEA;
  line-height: 1;
}

.info__list.-detail {
  padding: 0 0 20px;
  border: none;
}

.info__tag {
  padding: 4px 8px;
  margin: 0 10px 0 18px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  vertical-align: 1px;
}

.info__tag.-emargency {
  color: #fff;
  background-color: #C94701;
}

.info__tag.-important {
  border: 1px solid #C94701;
  color: #C94701;
}

.info__tag.-important-red {
  color: #C94701;
  background-color: #fff;
  border: 1px solid #C94701;
}

.info__tag.-new {
  color: #3157BC;
  border: 1px solid #3157BC;
}

.info__tag.-guidance {
  color: #fff;
  background-color: #3157BC;
  margin-left: 0;
}

.info__tag .visited {
  color: #A342C1;
}

.top-flow {
  padding: 100px 0;
}

.top-flow__grid {
  position: relative;
}

.top-flow__grid:last-of-type:after {
  content: none;
}

.top-flow__grid:after {
  content: '';
  background: url(/assets/img/cmn_icon_arrow-l.svg) center left/cover;
  width: 45px;
  height: 33px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 150px);
  right: calc(-10% - 18px);
}

.top-flow__list {
  text-align: center;
}

.top-flow__img {
  width: 110px;
  margin: 0 auto 20px;
}

.top-flow__tit {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
  height: 48px;
}

.top-flow__step {
  color: #3157BC;
  margin-bottom: 12px;
  font-weight: bold;
}

.top-flow__morebtn {
  text-align: center;
}

.top-flow__morebtn .morebtn {
  margin-top: 24px;
}

.top-flow__desc {
  text-align: left;
  height: 130px;
  max-width: 360px;
  margin: 0 auto;
}

.guide__card {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  flex-wrap: wrap;
}

.guide__card__arrow {
  width: auto;
}

.guide__card__img {
  width: 119px;
}

.guide__card__desc {
  width: 260px;
  padding-left: 32px;
  display: flex;
  align-items: center;
}

.guide__card__desc__img {
  width: 64px;
  margin-right: 16px;
}

.guide__left {
  display: flex;
  align-items: center;
  width: 300px;
}

.guide__left__desc {
  margin-left: 20px;
}

.guide__left-second {
  display: flex;
  align-items: center;
  width: 300px;
}

.guide__left-second__desc {
  margin-left: 20px;
}

.guide__device {
  background-color: #F4F9FC;
  padding: 30px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.guide__device__img {
  width: 156px;
  margin-right: 30px;
}

.guide__device__txt__tit {
  margin-bottom: 10px;
}

.bgblue__wrapper {
  background-color: #F4F9FC;
  padding: 15px 30px;
  margin-bottom: 30px;
}

.guide-flow {
  width: calc(50% - 25px);
  margin-top: 60px;
}

.guide-flow:first-of-type {
  border-right: 1px solid #CCDADB;
  padding-right: 25px;
  width: 50%;
}

.guide-flow__desc {
  text-align: center;
  font-weight: bold;
}

.guide-flow__desc .-brackets {
  font-size: 12px;
}

.guide-flow__btn {
  text-align: center;
}

.guide-flow__btn .morebtn {
  margin: 30px auto;
}

.guide-flow__btn .button {
  width: 100%;
}

.guide-flow__btn.-document {
  width: 360px;
}

.guide-flow__heading {
  margin: 0 0 30px 0;
  text-align: center;
}

.guide-flow__heading__img {
  height: 110px;
  width: auto;
}

.guide-about {
  color: #3157BC;
}

.guide-about.bg {
  background: url("/assets/img/guide0_bg_img01.svg");
  background-size: 100%;
}

.guide-about.blue {
  background-color: #F4F9FC;
}

.guide-about__wrapper {
  padding: 54px 0 50px;
}

.guide-about__tit {
  font-size: 24px;
  text-align: center;
}

.guide-about__img {
  width: 570px;
  margin: 0 auto;
  padding: 37px 0  30px;
}

.guide-about__desc {
  border: 1px solid #6C85D8;
  border-radius: 5px;
  padding: 16px 13px;
  font-size: 12px;
  max-width: 740px;
  margin: 0 auto;
}

.section-anchor {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-anchor.-two-column {
  width: 740px;
}

.section-anchor.-two-column li:nth-of-type(even) {
  width: 320px;
}

.section-anchor.-short {
  justify-content: start;
}

.section-anchor.-column {
  display: block;
}

.section-anchor__wrapper {
  padding: 0 30px 0 30px;
}

.section-anchor__list {
  width: 370px;
  margin-bottom: 10px;
}

.section-anchor__list a:before {
  content: '';
  background: url(/assets/img/cmn_icon_anchor.svg) center left/cover;
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.section-anchor__list.-short {
  width: auto;
  margin-right: 50px;
}

.section-anchor__list.-column {
  width: auto;
}

.section-anchor__list.faq-details {
  width: 370px !important;
}

.section-link__list {
  margin: 4px 0;
  display: inline-block;
}

.section-link__list:before {
  content: '';
  background: url(/assets/img/cmn_icon_arrow-s.svg) center left/cover;
  width: 4px;
  height: 6px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: 2px;
}

.section-link__list.-external:after {
  content: '';
  background: url(/assets/img/cmn_icon_popwin.svg) center left/cover;
  width: 14px;
  height: 11px;
  display: inline-block;
  margin-left: 8px;
}

.section-link__list.-step-down {
  padding: 0 0 0 10px;
  position: relative;
}

.section-link__list.-step-down:before {
  position: absolute;
  top: 8px;
  left: 0px;
}

.section-link__list.-pdf:after {
  content: '';
  background: url(/assets/img/cmn_icon_pdf.svg) center left/cover;
  width: 12px;
  height: 14px;
  display: inline-block;
  margin-left: 8px;
}

.section-link__blank-txt {
  font-size: 12px;
  margin: 0 0 0 6px;
}

.section-toggle {
  border: #CCDADB 1px solid;
  border-radius: 4px;
  background-color: #F4F9FC;
  box-shadow: 0 2px 0 #B7C9CB;
  margin-top: 30px;
}

.section-toggle.-active {
  box-shadow: none;
}

.section-toggle.-active .section-toggle__trigger.-tit:after {
  background: url(/assets/img/cmn_icon_toggle-on.svg) top left/cover;
}

.section-toggle__trigger {
  cursor: pointer;
}

.section-toggle__trigger .-tit {
  padding: 20px;
  font-weight: bold;
  position: relative;
}

.section-toggle__trigger .-tit:hover {
  background-color: #DEEDF5;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.section-toggle__trigger .-tit:after {
  display: inline-block;
  content: '';
  width: 34px;
  height: 34px;
  background: url(/assets/img/cmn_icon_toggle-off.svg) top left/cover;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.section-toggle__content {
  border-top: 2px solid #B7C9CB;
}

.section-toggle__inner {
  margin: 20px;
}

.section-table {
  width: calc(100% - 40px);
  margin: 20px;
  background-color: #fff;
}

.section-table__heading {
  background-color: #3157BC;
  color: #fff;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  border-right: 2px solid #F4F9FC;
  border-bottom: 2px solid #F4F9FC;
}

.section-table__desc {
  padding: 20px;
  border-right: 2px solid #F4F9FC;
  border-bottom: 2px solid #F4F9FC;
}

.section-table__desc.-short {
  padding: 10px 20px 10px 20px;
}

.guide-online {
  border-top: 1px solid #EAEAEA;
  margin-top: 30px;
}

.guide-online__wrapper {
  display: flex;
}

.guide-online__left {
  width: 50px;
  margin-right: 15px;
}

.guide-online__right {
  border-top: 1px solid #EAEAEA;
  padding-top: 30px;
  width: 100%;
}

.guide-online__right.border-none {
  border: none;
}

.guide-online__tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
}

.guide-online__desc {
  margin-bottom: 12px;
}

.guide-online__img {
  width: 50px;
  padding-top: 30px;
}

.guide-online__line {
  margin: -8px auto 0;
  border: 3px solid #CCDADB;
  width: 3px;
  height: 80%;
}

.guide-online__line.-short {
  height: 73%;
}

.guide-online__triangle {
  margin: -5px auto 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #CCDADB;
}

.guide-online__circle {
  margin: -5px auto 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #CCDADB;
}

.guide-step {
  padding: 10px 12px 8px;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  display: block;
  background-color: #F4F9FC;
  border: 1px solid #CCDADB;
  border-radius: 4px;
  text-decoration: none;
  width: 30%;
  min-width: 230px;
}

.guide-step:hover {
  background-color: #DEEDF5;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.guide-step__sticky {
  position: inherit;
  left: 0px;
  width: 100%;
  z-index: 97;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.guide-step__sticky.-shrink {
  position: sticky;
  top: 70px;
}

.guide-step__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
  padding: 20px 30px;
  background-color: #fff;
}

.guide-step.-long {
  padding: 8px 10px;
}

.guide-step.-shrink {
  padding: 2px 10px 1px;
}

.guide-step.-shrink .-num {
  margin: -7px 0 0 0;
}

.guide-step.-current {
  background-color: #3157BC;
}

.guide-step.-current .guide-step__heading {
  color: #CBD5EE;
}

.guide-step.-current .guide-step__desc {
  color: #fff;
}

.guide-step__list {
  display: flex;
  align-items: center;
  position: relative;
}

.guide-step__heading {
  font-size: 13px;
  font-weight: bold;
  color: #707272;
  padding: 0 7px 0 0;
  margin: 0px 10px 0 0;
  border-right: 1px solid #CCDADB;
  text-align: center;
}

.guide-step__heading .-num {
  font-size: 33px;
  display: block;
  line-height: 1;
  border-right: none;
  margin: -2px 0 0 0;
  padding: 0 1px 0 0;
}

.guide-step__desc {
  color: #444;
  font-weight: bold;
  line-height: 22px;
}

.guide-step__desc span {
  display: block;
  font-size: 12px;
  margin-left: -10px;
  white-space: nowrap;
}

.guide-step__desc span.-column {
  margin-left: 5px;
}

.guide-step__desc.-column {
  display: flex;
}

.guide-step__arrow {
  width: 0;
  height: 0;
  border-left: 15px solid #3157BC;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.guide-step__arrow.-down {
  border: none;
  width: 17px;
  height: 30px;
  margin: 0 auto;
  display: block;
}

.guide-step.-shadow {
  box-shadow: 0 2px 0 #b7c9cb;
}

a.guide-step.-flow {
  width: 100%;
}

a.guide-step.-flow .guide-step__list:after {
  content: '';
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  transform: rotate(45deg);
  border-top: 2px solid #444;
  border-right: 2px solid #444;
}

.step-box {
  padding: 10px 12px 8px;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  display: block;
  background-color: #F4F9FC;
  border: 1px solid #CCDADB;
  border-radius: 4px;
  text-decoration: none;
  width: 30%;
  min-width: 230px;
}

.step-box:hover {
  background-color: #DEEDF5;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.step-box.-shadow {
  box-shadow: 0 2px 0 #b7c9cb;
}

a.step-box.-flow {
  width: 100%;
}

.step-box-arrow::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 0px;
  transform: rotate(45deg);
  border-top: 2px solid #444;
  border-right: 2px solid #444;
}

.guide-login {
  background-color: #f4f9fc;
  padding: 66px 0 62px;
  margin-top: 30px;
  margin-bottom: 33px;
}

.guide-login__hedding {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 54px;
}

.guide-login__wrapper {
  width: 654px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.guide-login__box {
  text-align: center;
  background-color: #fff;
  border: 1px solid #CCDADB;
  border-radius: 2%;
  width: 300px;
  padding: 30px 30px;
}

.guide-login__box:nth-child(3) {
  width: 400px;
  margin: 55px auto 0;
  padding: 30px 23px;
}

.guide-login__box:nth-child(3) p:last-child {
  text-align: left;
}

.guide-login__img {
  width: 102px;
  margin: 0 auto 18px;
}

.guide-login__tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.guide-login__desc {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

.guide-login__desc:nth-child(4) {
  margin: 17px 0 12px;
  text-align: center;
}

.guide-login__desc span {
  width: calc(100% - 16px);
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
}

.guide-login__arrow-left {
  position: absolute;
  top: 268.5px;
  left: 50px;
}

.guide-login__arrow-right {
  position: absolute;
  top: 268.5px;
  right: 50px;
}

.guide-login__btn {
  text-align: center;
}

.guide-login__btn .button {
  width: 360px;
}

.guide-request {
  display: flex;
  justify-content: center;
  margin-top: 55px;
}

.guide-request__wrapper.left {
  width: 65%;
  border-right: 1px solid #E7E7E7;
  padding-right: 55px;
}

@media screen and (max-width: 1000px) {
  .guide-request__wrapper.left.left {
    padding-right: 40px;
  }
}

.guide-request__wrapper.left.second {
  width: 50%;
  padding-right: 40px;
  padding-left: 27px;
}

.guide-request__wrapper.right {
  width: 35%;
  padding-left: 55px;
}

@media screen and (max-width: 1016px) {
  .guide-request__wrapper.right.right {
    padding-left: 40px;
  }
}

.guide-request__wrapper.right.second {
  width: 50%;
  padding-left: 40px;
  padding-right: 27px;
}

.guide-request__img {
  width: 150px;
  margin: 30px auto 0;
}

.guide-request__tit {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 23px 0 25px;
}

.guide-request__desc {
  height: 200px;
}

.guide-request__btn {
  margin-bottom: 25px;
}

.guide-request__list {
  height: 120px;
  margin-bottom: 23px;
}

.guide-request__list .-inner span {
  width: 90%;
  display: inline-block;
  height: 0;
}

.guide-request__list .-inner::before {
  content: "・";
  vertical-align: top;
}

.guide-request__info {
  margin-bottom: 30px;
}

.guide-request__info .tit {
  font-weight: bold;
  margin-bottom: 10px;
}

.guide-movie {
  width: 49%;
}

.guide-movie__wrapper {
  display: flex;
  justify-content: space-between;
}

.guide-movie__tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.guide-movie__thumbnail img {
  width: 100%;
}

.guide-movie__time {
  font-size: 16px;
  font-weight: normal;
  color: #444444;
  margin-top: 5px;
}

.news__wrapper {
  margin-bottom: 30px;
}

.news__tit {
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 16px 10px;
}

.news__under-line {
  border-bottom: 1px solid #EAEAEA;
}

.news-details {
  padding: 0 30px 0 30px;
}

.news-details__tit {
  font-size: 32px;
  font-weight: normal;
  color: #3157BC;
  padding-bottom: 40px;
}

.news-details__date .top__info__list {
  padding: 0 0 24px;
  border-top: none;
}

.news-details__date .top__info__list__date {
  font-size: 16px;
}

.news-details__date .top__info__list .visited {
  color: #A342C1;
}

.news-details__wrapper {
  border-top: 1px solid #EAEAEA;
  padding: 50px 0 100px;
}

.news-details__txt {
  font-size: 16px;
}

.news-details__txt.-list {
  display: flex;
}

.faq__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 10px;
}

.faq__wrapper:last-child {
  border-bottom: none;
}

.faq__inner {
  width: 48.5%;
  padding-bottom: 30px;
}

.faq__btn {
  margin: 0 0 20px;
  display: block;
  height: 60px;
}

.faq__btn span {
  font-size: 12px;
  display: block;
  padding-top: 10px;
}

.faq__question .question {
  color: #3157BC;
  font-weight: bold;
  margin-right: 4px;
}

.faq__question .txt-box {
  display: inline-block;
  width: 90%;
  vertical-align: top;
}

.faq__answer {
  display: flex;
  padding: 24px 20px;
}

.faq__answer .answer {
  color: #C94701;
  font-weight: bold;
  margin-right: 4px;
}

.faq__toggle {
  margin-top: 20px;
}

.faq-details__tit {
  margin-bottom: 20px;
}

.contact__wrapper {
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 42px;
  margin-bottom: 42px;
}

.contact__wrapper .txt-note {
  margin-bottom: 24px;
}

.contact__wrapper:nth-child(3) {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 50px;
}

.contact__tit {
  text-align: center;
  font-size: 16px;
  color: #3157BC;
}

.contact__access {
  display: flex;
  justify-content: start;
  text-decoration: none;
  font-size: 34px;
  color: #3157BC;
  margin-bottom: 33px;
}

.contact__access img {
  width: 34px;
  margin-right: 8px;
}

.contact__subtxt {
  display: block;
  margin: 16px 0 0 16px;
}

.footer {
  border-top: #E7E7E7 1px solid;
  padding: 20px 0;
  text-align: center;
}

.footer__wrapper {
  border-bottom: #E7E7E7 1px solid;
  padding-bottom: 20px;
}

.footer__list {
  font-size: 12px;
  display: inline-block;
}

.footer__list::after {
  content: "|";
  padding: 0 2px 0 8px;
}

.footer__list:last-child::after {
  display: none;
}

.footer__logo {
  width: 125px;
  height: 45px;
  margin: 20px auto;
}

.copyright {
  font-size: 12px;
  color: #A7A7A7;
}

.modaal-gallery-item img {
  max-height: 80vh;
}

.modaal-wrapper .modaal-close {
  background: #3157BC;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 0 #000e5c;
}

.modaal-close:after, .modaal-close:before {
  top: 12px;
  left: 19.5px;
  width: 2px;
  height: 16px;
  border-radius: 0px;
}

.modaal-close {
  position: absolute;
  right: -20px;
  top: -20px;
}

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #fff;
}

.modal-content {
  cursor: pointer;
}

.modal-content__wrapper {
  padding: 30px 25px;
}

.modal-content__tit {
  font-size: 32px;
  color: #3157BC;
  text-align: center;
  margin-bottom: 30px;
}

.modal-content__desc {
  margin: 0 auto 40px;
}

.modal-content__desc.-box {
  width: 640px;
}

.modaal-outer-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modaal-inner-wrapper {
  padding: 0 !important;
  height: auto;
  max-width: 900px;
}

.modaal-video-wrap {
  margin: 0;
}

.modal-btn__blue {
  padding-top: 30px;
  border: none;
}

.modal-btn__blue a {
  width: 220px;
  height: 45px;
  cursor: pointer;
}

.modal-btn__blue a::after {
  display: none;
}

.modal-btn__yellow {
  display: block;
  width: 360px;
  margin: 0 auto;
  padding: 0;
}

.modal-btn__yellow span {
  display: block;
  padding-top: 4px;
}

.modal-btn__yellow__wrapper {
  display: flex;
}

.modal-btn__yellow.-second {
  height: 75px;
}

.modal__list {
  margin-bottom: 30px;
}

.modal__table {
  width: 100%;
}

.modal__table th {
  padding: 5px 10px;
  border: 1px solid #ccdadb;
  background-color: #3157bc;
  text-align: center;
  color: #fff;
}

.modal__table td {
  padding: 10px;
  border: 1px solid #ccdadb;
  background-color: #f4f9fc;
  text-align: center;
}

.modal__table td.-ok {
  color: #3157bc;
}

.modal__table td.-ng {
  background-color: #fff8f2;
  color: #c94701;
}

@media screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 56.25%;
    height: auto;
  }
}

.right-btn {
  margin-left: 30px;
  width: auto;
}

.mt0 {
  margin-top: 0 !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt64 {
  margin-top: 64px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.pl0 {
  padding-left: 0 !important;
}

::placeholder {
  color: #aaa;
}

/* 旧Edge対応 */
::-ms-input-placeholder {
  color: #aaa;
}

/* IE対応 */
:-ms-input-placeholder {
  color: #aaa;
}

.modaal-close-fix {
  right: unset !important;
  top: unset !important;
  background: unset !important;
  box-shadow: unset !important;
  padding: 10px !important;
  text-decoration: none !important;
  position: relative !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #3157bc !important;
  flex-wrap: wrap !important;
  border: 1px solid #3157bc !important;
  border-radius: 50px !important;
  width: 220px !important;
  height: 45px !important;
}

.modaal-close-fix::after {
  display: none !important;
}

.modaal-close-fix::before {
  display: none !important;
}
