//reset
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

//plugin
@import '~modaal/dist/css/modaal.scss';

@import "_library/_animation";
@import "_library/_colors";
@import "_library/_mixin";
@import "_library/_localmixin";
@import "_components/_layout";
@import "_components/_footer";
@import "_components/_nav";
@import "_components/_content";
@import "_components/_page";

html{
    font-family: "Arial","Meiryo","MS PGothic","Hiragino Kaku Gothic ProN","Osaka",sans-serif;
    font-size: map-get($_font-size, md);
    margin: 0;
    padding: 0;
}
body{
    background-color: #fff;
    color: $color-black;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    box-sizing: border-box;
}
@media screen and (min-width: 985px) {
    body {
        overflow-x: hidden;
    }
}

a{
    text-decoration: underline;
    color:#0473C6;
    &:visited{
        color: #A342C1;
    }
    &:hover{
        text-decoration: none;
    }
}
*{
    box-sizing: border-box;
}
.only-sp {
    @include mq(sm, min){
        display: none;
    }
}
.only-pc {
    @include mq(sm, max){
        display: none;
    }
}
$color-main:#3157BC;
img{
    width:100%;
    height: auto;
}
ol,li{
    list-style: none;
}
//header
.header{
    padding: 10px 32px 10px 20px;
    display:flex;
    justify-content: space-between;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    position: fixed;
    z-index: 10;
    background-color: #fff;
    align-items: center;
    width: 100%;
    z-index: 99;
    &.-simple{
        height: 70px;
    }
    &__logo{
        font-size: 18px;
        font-weight: bold;
        a{
            color:$color-main;
            text-decoration: none;
        }    
    }
}

.header-info{
    display: flex;
    align-items: center;
    &__tit{
        margin-left: 20px;
        padding-left: 20px;
        font-weight: bold;
        font-size: 12px;
        border-left:1px solid #CCDADB;
    }
    .tel{
        line-height: 1;
        margin-left: 15px;
        padding:6px 15px 6px 8px;
        border-radius: 4px;
        border:1px solid #CCDADB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__img{
            width: 18px;
            margin-right: 10px;
        }
        &__num{
            font-size: 20px;
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 4px;
        }
        &__desc{
            font-size: 12px;
            border-left:1px solid #CCDADB;
        }
        &__link:before{
            content: '';
            background: url(/assets/img/cmn_icon_arrow-s.svg) center left/cover;
            width: 4px;
            height: 6px;
            display: inline-block;
            margin-right: 4px;
            vertical-align: 2px;
        }
        &__content{
            padding-right: 14px;
            margin-right: 14px;
            border-right: #CCDADB 1px solid;
        }
    }
}

//sidenav
.sidenav{
    width:200px;
    background-color: #F4F7FA;
    padding:15px 10px 0;
    line-height: 20px;
    position:fixed;
    min-height: 100vh;
    z-index: 98;
    &__mov{
        background-color: #fff;
        border-radius: 4px;
        font-size: 12px;
        width: 100%;
        padding:20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color:#444;
        text-decoration: none;
        line-height: 16px;
    }
}
.sidenav-list{
    border-bottom:1px solid #D2E1EB;
    font-weight: bold;
    height: 60px;
    a {
        color:#444444;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 60px;
        margin-left: 10px;
    }
    &__icon{
        width:30px;
        height:30px;
        margin-right: 10px;
    }
    &:last-of-type{
        border:none;
    }
    &__txt{
        color: #3157BC;
    }
}



//layout
.container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-width: 1000px;
    margin-top: 70px;
    &.-login{
        min-width: 900px;
    }
}
.main__wrapper{
    width:calc(100% - 200px);
    margin-left: 200px;
}
.main{
    padding: 0 0 100px;
    min-width: 800px;
}
//common
.breadcrumb + .section{
    padding:10px 30px 100px;
}
.section{
    margin:100px 0;
    padding:0 30px;
    &:last-child{
        margin-bottom: 0;
    }
    &.-bgblue{
        background-color: #F4F9FC;
        margin-left: 0;
        margin-right: 0;
    }
    &__tit{
        font-size: 32px;
        text-align: center;
        color:$color-main;
        margin-bottom:50px;
        &:after{
            content:"";
            height:5px;
            width:50px;
            background:$color-main;
            display: block;
            margin:20px auto 0;
        }
    }
    &__heading{
        padding:20px 0;
        font-size: 20px;
        border-top:3px solid $color-main;
        border-bottom:1px solid $color-main;
        color:$color-main;
        text-align: center;
        margin-bottom: 50px;
    }
    &__flexcolumn{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &.-center{
            justify-content: center;
        }
    }
    &__gridcolumn{
        display:grid;
        grid-template-columns: auto auto auto;
        column-gap: 55px;
    }
    &__card{
        border: 1px solid #CCDADB;
        padding:30px;
        border-radius: 4px;
        margin-bottom: 20px;
        &__tit{
            text-align: center;
            font-weight: bold;
            margin-bottom: 20px;
        }
        &:nth-of-type(2) .guide__card__img{
            height: 64px;
            width: auto;
        }
    }
    &__hr{
        border-bottom: 1px solid #EAEAEA;
        border-top:none;
        border-right: none;
        border-left: none;
        margin: 20px 0;
    }
    &__subheading{
        font-size: 20px;
        font-weight: bold;
        margin: 50px 0 20px;
        &.in-box{
            font-size: 16px;
            margin: 0 0 20px;
        }
        &.icon{
            img{
                width: 20px;
                margin-right: 8px;
                vertical-align: top;
            }
        }
        &.border-top{
            border-top: 1px solid #EAEAEA;
            padding-top: 40px;
        }
    }
    &__txt{
        margin-bottom: 30px;
        &.-center{
            text-align: center;
        }
        &.-list{
            line-height: 1.8;
        }
    }
    &__attention{
        padding:0 20px 20px 20px;
        &-txt{
            font-size: 12px;
        }
    }
    &__button{
        display: flex;
        justify-content: center;
        margin: 30px 0 50px;
        &.net-framework{
            margin: 30px 0 0;
        }
        a{
            &.-external{
                &:before{
                    content: '';
                    background: url(/assets/img/cmn_icon_popwin-white.svg) center left/cover;
                    width: 14px;
                    height: 11px;
                    display: inline-block;
                    position: absolute;
                    right: 40px;
                }
            }
        }
    }
    &__box-line{
        border: #CCDADB solid 1px;
        border-radius: 4px;
        padding: 30px 40px;
    }
    &__add-group{
        dl{
            margin: 30px 0 20px;
        }
    }
}
.heading{
    padding:0 30px 0 30px;
    &__tit{
        font-size: 40px;
        color:$color-main;
        margin-bottom: 32px;
        line-height: 1.3;
        &.-wrap{
            white-space: nowrap;
        }
        span{
            font-size: 32px;
        }
        &.-fixed{
            padding: 132px 30px 0 30px;
        }
    }
    &__desc{
        font-size: 16px;
    }
    &.-single{
        margin: 0 0 100px;
        padding: 0;
    }
    &__complete {
        display: flex;
        justify-content: space-between;
        padding: 16px 56px 16px 40px;
        align-items: center;
        background-color: #F4F9FC;
        margin: 0px 0 50px;
        b {
            color: #C94701;
            font-weight: bold;
            font-size: 20px;
        }
    }
    &__list{
        margin-top: 20px;
    }
}
.breadcrumb{
    padding:32px 30px;
    &__list{
        font-size: 12px;
        display: inline-block;
        & a:after{
            content:'';
            width:6px;
            height:6px;
            border-top: 1px solid #444;
            border-right: 1px solid #444;
            transform: rotate(45deg);
            display: inline-block;
            margin: 0 8px;
            vertical-align: 1px;
        }
    }   
}
.txt{
    &-bold{
        font-weight: bold;
    }
    &-attention{
        color:#c94701;
    }
    &-form-att{
        font-size: 12px;
    }
    &-goiken{
        line-height: 3;
    }
    &-center{
        text-align: center;
    }
    &-align-middele{
        vertical-align: middle;
    }
    &-small{
        font-size: 12px;
    }
    &-dia{
        font-size: 12px;
        vertical-align: middle;
        margin-right: 2px;
    }
    &-margin{
        display: block;
        height: 10px;
    }
    &-middle{
        vertical-align: middle;
    }
    &-asterisk{
        font-size: 12px;
        color: #444;
    }
    &-form-error{
        margin: 0 0 20px;
        color: #C94701;
    }
    &-login-error{
        margin: 0 0 10px;
        color: #C94701;
    }
    &-attention-confirm{
        color: #C94701;
        font-size: 16px;
        margin-top: 15px;
    }
    &-note{
        display: flex;
        align-items: center;
        &.-top{
            align-items: flex-start;
        }
        .asterisk{
            font-size: 12px;
            margin-right: 4px;
        }
    }
    &-blue{
        color: $color-main;
    }
}

// button
.button{
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    line-height: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    &.-prime{
        background-color: $color-main;
        box-shadow: 0 2px 0 #000E5C;
        color: #fff;
        padding:22px 0;
        &.-center{
            width: 390px;
        }
        &:hover{
            background-color: #2D61EA;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
        &.guide13{
            width: 100%;
            font-size: 16px;
            line-height: 1.6;
            padding: 11px 0  6px;
        }
        &.guide0{
            transform: translateX(-32.5px);
        }
    }
    &.-main{
        background-color: $color-main;
        box-shadow: 0 2px 0 #000E5C;
        color: #fff;
        padding:22px 0;
        width:360px;
        &:hover{
            background-color: #2D61EA;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
    }
    &.-main{
        background-color: #F5E06B;
        box-shadow: 0 2px 0 #B1923E;
        color: #fff;
        padding:22px 0;
        width:360px;
        &:hover{
            background-color: #FFED86;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
    }
    &.-third{
        background-color: #F4F9FC;
        box-shadow: 0 2px 0 #B7C9CB;
        border: 1px solid #CCDADB;
        color: #444;
        padding:22px 0;
        width:360px;
        height: 60px;
        border: transparent;
        &:hover{
            background-color: #DEEDF5;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
    }
    &.-second{
        background-color: #F5E06B;
        box-shadow: 0 2px 0 #B1923E;
        color: #444444;
        padding:22px 0;
        font-size: 15px;
        &:hover{
            background-color: #FFED86;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
        &:after{
            border-top:2px solid #444444;
            border-right:2px solid #444444;
            }
        &.guide13{
            width: 100%;
            font-size: 16px;
            line-height: 1.6;
            padding: 11px 0  6px;
        }
        &.modal{
            padding: 12px 0;
            width: 360px;
            display: block;
            &.-first{
                height: 75px;
                line-height: 1.6;
            }
        }
        &.-hero{
            height: 40px;
        }
        &.guide10{
            height: 60px;
        }
    }
    &.-window{
        border: none;
    }
    &.-h60{
        height: 60px;
    }
    &.-third{
        background-color: #F4F9FC;
        box-shadow: 0 2px 0 #B7C9CB;
        color: #444444;
        padding:22px 0;
        font-size: 16px;
        &:hover{
            background-color: #DEEDF5;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
        &:after{
            border-top:2px solid #444444;
            border-right:2px solid #444444;
            }
        &.-column{
            padding: 12px 0;
        }
    }


    &.-login{
        background-color: $color-main;
        box-shadow: 0 2px 0 #000E5C;
        color: #fff;
        width:120px;
        height: 40px;
        display: flex;
        align-items: center;
        &:hover{
            background-color: #2D61EA;
            transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        }
    }
    &:after{
        content:'';
        width:8px;
        height:8px;
        border-top:2px solid #fff;
        border-right:2px solid #fff;
        display: inline-block;
        position: absolute;
        top:calc(50% - 6px);
        right:12px;
        transform: rotate(45deg);
    }
    &.-center{
        margin: 0 auto;
    }
    .-popwin-w{
        display: block;
        position: relative;
        padding: 0 25px 0 0;
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: 0.1em;
            right: 0;
            width: 15px;
            height: 12px;
            background-repeat: no-repeat;
            background-image: url('../img/cmn_icon_popwin-w.svg');
        }
    }
}

// motebtn
.morebtn{
    border: 1px solid $color-main;
    color: $color-main;
    border-radius: 40px;
    padding:10px 60px;
    display: inline-block;
    margin-top: 54px;
    text-decoration: none;
    position: relative;
    text-align: center;
    cursor: pointer;
    &:hover{
        background-color: #EAF4FF;
        transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    }
    &:after{
        content:'';
        width:8px;
        height:8px;
        border-top:2px solid $color-main;
        border-right:2px solid $color-main;
        display: inline-block;
        position: absolute;
        top:calc(50% - 6px);
        right:12px;
        transform: rotate(45deg);
    }
    &.-close{
        padding: 18px 65px;
        &::after{
            display: none;
        }
    }
    &:visited{
        color: #3157BC;
    }
}

// footer-btn
.footer-btn{
    padding: 10px;
    display: block;
    border: 1px solid #3157BC;
    border-radius: 50px;
    text-decoration: none;
    width: 48%;
    position: relative;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#3157BC;
    flex-wrap: wrap;
    &:hover{
        background-color: #EAF4FF;
        transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    }
    &:visited{
        color: #3157BC;
    }
    &__num{
        font-size: 12px;
        display: block;
        width:100%;
    }
    &__small-txt{
        font-size: 12px;
    }
    &:after{
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        position: absolute;
        transform: rotate(45deg);
        border-top: 2px solid #3157BC;
        border-right: 2px solid #3157BC;
    }
    &.-next:after{
        top: calc(50% - 6px);
        right: 12px;
    }
    &.-back:after{
        top: calc(50% - 6px);
        left: 12px;
        transform: rotate(225deg);
    }
    &__wrapper{
        padding:100px 0 0;
        border-top:1px solid #eaeaea;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.-center{
            justify-content: center;
        }
        &.-complete{
            padding: 50px 0 0 0;
            justify-content: center;
        }
    }
}

//content
.significant{
    background-color: #FFF8F2;
    padding: 22px 30px;
    display: flex;
    line-height: 1;
    &__icon{
        width:16px;
        vertical-align: -2px;
        margin-right: 10px;
    }
    &__tit{
        color: #C94701;
        font-weight: bold;
        margin-right: 30px;
    }
    &__desc{
        &:before{
            content:'';
            background:url(/assets/img/cmn_icon_arrow-s.svg) center left / cover;
            width: 4px;
            height:6px;
            display: inline-block;
            margin-right: 8px;
            vertical-align: 2px;
        }
    }
}
.hero{
    padding:53px 30px 40px;
    background:url(/assets/img/top_mv@2x.png) top center / auto 100% no-repeat;
    @media screen and (min-width:1920px) {
        background:url(/assets/img/top_mv@2x.png) top right / auto 100% no-repeat;
    }
    &__lead{
        font-size: 28px;
        line-height: 36px;
        color:$color-main;
        margin-bottom: 33px;
        &.-shoulder{
            font-size: 16px;
            margin-bottom: 16px;
            line-height:1;
            display: block;
        }
    }
    &__primecta{
        width: 390px;
    }
    &__secondcta{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 390px;
        margin-top: 10px;
        .-second{
            width:190px;
        }
    }
    &__attention{
        font-size: 12px;
        color:#444;
        width:264px;
        margin-top: 38px;
        line-height: 16px;
    }
}
.merit{
    &__flex{
        width:31%;
    }
    &__list{
        text-align: center;
    }
    &__img{
        width:191px;
        margin: 0 auto;
    }
    &__tit{
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &__desc{
        text-align: left;
        min-height: 100px;
        max-width: 360px;
        margin: 0 auto;
    }
}

.info{
    &__list{
        display: flex;
        padding: 24px 10px;
        border-top: 1px solid #EAEAEA;
        line-height: 1;
        &.-detail{
            padding: 0 0 20px;
            border: none;
        }
    }
    &__tag{
        padding:4px 8px;
        margin: 0 10px 0 18px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        vertical-align: 1px;
        &.-emargency{
            color:#fff;
            background-color: #C94701;
        }
        &.-important{
            border: 1px solid #C94701;
            color: #C94701;
        }
        &.-important-red{
            color:#C94701;                
            background-color: #fff;
            border:1px solid #C94701;
        }
        &.-new{
            color:$color-main;
            border:1px solid $color-main;
        }
        &.-guidance{
            color:#fff;                
            background-color: $color-main;
            margin-left: 0;
        }
        .visited{
            color: #A342C1;
        }
    }
}

.top{
    &-flow{
        padding: 100px 0;
        &__grid{
            position: relative;
            &:last-of-type:after{
                content:none;
            }
            &:after{
                content:'';
                background:url(/assets/img/cmn_icon_arrow-l.svg) center left / cover;
                width: 45px;
                height:33px;
                display: inline-block;
                position: absolute;
                top: calc(50% - 150px);
                right: calc(-10% - 18px);
            }
        }
        &__list{
            text-align: center;
        }
        &__img{
            width:110px;
            margin: 0 auto 20px;
        }
        &__tit{
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 20px;
            height:48px;
        }
        &__step{
            color:$color-main;
            margin-bottom: 12px;
            font-weight: bold;
        }
        &__morebtn{
            text-align: center;
        }
        &__morebtn .morebtn{
            margin-top: 24px;
        }
        &__desc{
            text-align: left;
            height: 130px;
            max-width: 360px;
            margin: 0 auto;
        }
    }
    

}
.guide{
    &__card{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;   
        padding: 0 16px;
        flex-wrap: wrap;
        &__arrow{
            width: auto;
        }
        &__img{
            width:119px;
        }
        &__desc{
            width: 260px;
            padding-left: 32px;
            display: flex;
            align-items: center;
            &__img{
                width:64px;
                margin-right: 16px;
            }
        }
    }
    &__left{
        display: flex;
        align-items: center;
        width: 300px;
        &__desc{
            margin-left: 20px;
        }
    }
    &__left-second{
        display: flex;
        align-items: center;
        width: 300px;
        &__desc{
            margin-left: 20px;
        }
    }
    &__device{
        background-color: #F4F9FC;
        padding:30px;
        display: flex;
        align-items: center;
        margin-top: 30px;
        &__img{
            width:156px;
            margin-right: 30px;
        }
        &__txt{
            &__tit{
                margin-bottom:10px;
            }
        }
    }
}

.bgblue__wrapper{
    background-color: #F4F9FC;
    padding: 30px;
    margin-bottom: 30px;
}

.guide-flow{
    width: calc(50% - 25px);
    margin-top: 60px;
    &:first-of-type{
        border-right: 1px solid #CCDADB;
        padding-right: 25px;
        width: 50%;
    }
    &__desc{
        text-align: center;        
        font-weight: bold;
        .-brackets{
            font-size: 12px;
        }
    }
    &__btn{
        text-align: center;
        .morebtn{
            margin: 30px auto;
        }
        .button{
            width: 100%;
        }
        &.-document{
            width: 360px;
        }
    }
    &__heading{
        margin: 0 0 30px 0;
        text-align: center;
        &__img{
            height:110px;
            width:auto;
        }
    }
}

// guide-about
.guide-about{
    color: #3157BC;
    &.bg{
        background: url("/assets/img/guide0_bg_img01.svg");
        background-size: 100%;

    }
    &.blue{
        background-color: #F4F9FC;
    }
    &__wrapper{
        padding: 54px 0 50px;

    }
    &__tit{
        font-size: 24px;
        text-align: center;
    }
    &__img{
        width: 570px;
        margin: 0 auto;
        padding: 37px 0  30px;
    }
    &__desc{
        border: 1px solid #6C85D8;
        border-radius: 5px;
        padding: 16px 13px;
        font-size: 12px;
        max-width: 740px;
        margin: 0 auto;
    }
}

// セクション共通パーツ
// section-ancar
.section-anchor{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.-two-column{
        width: 740px; 
        li:nth-of-type(even){
            width: 320px;
        }
    }
    &.-short{
        justify-content: start;
    }
    &.-column{
        display: block;
    }
    &__wrapper{
        padding:0 30px 0 30px;
    }
    &__list{
        width: 370px;
        margin-bottom: 10px;
        a{
            &:before{
                content: '';
                background: url(/assets/img/cmn_icon_anchor.svg) center left/cover;
                width: 19px;
                height: 19px;
                display: inline-block;
                margin-right: 8px;
                vertical-align: middle;
            }
        }
        &.-short{
            width: auto;
            margin-right: 50px;
        }
        &.-column{
            width: auto;
        }
        &.faq-details{
            width: 370px !important;
        }
    }
}

.section-link{
    &__list{
        margin: 4px 0;
        display: inline-block;
        &:before{
            content: '';
            background: url(/assets/img/cmn_icon_arrow-s.svg) center left/cover;
            width: 4px;
            height: 6px;
            display: inline-block;
            margin-right: 8px;
            vertical-align: 2px;
        }
        &.-external{
            &:after{
                content: '';
                background: url(/assets/img/cmn_icon_popwin.svg) center left/cover;
                width: 14px;
                height: 11px;
                display: inline-block;
                margin-left: 8px;
            }
        }
        &.-step-down{
            padding: 0 0 0 10px;
            position: relative;
            &:before{
                position: absolute;
                top: 8px;
                left: 0px;
            }
        }
        &.-pdf{
            &:after{
                content: '';
                background: url(/assets/img/cmn_icon_pdf.svg) center left/cover;
                width: 12px;
                height: 14px;
                display: inline-block;
                margin-left: 8px;
            }
        }
    }
    &__blank-txt{
        font-size: 12px;
        margin: 0 0 0 6px;
    }

}

// トグルボタン関係のレイアウト
.section-toggle{
    border:#CCDADB 1px solid;
    border-radius: 4px;
    background-color: #F4F9FC;
    box-shadow: 0 2px 0 #B7C9CB;
    margin-top: 30px;
    &.-active{
        box-shadow: none;
    }
    &.-active .section-toggle__trigger.-tit:after{
        background:url(/assets/img/cmn_icon_toggle-on.svg) top left / cover;
    }
    &__trigger{
        cursor: pointer;
        .-tit{
            padding:20px;
            font-weight: bold;
            position: relative;
            &:hover{
                background-color: #DEEDF5;
                transition: all .2s cubic-bezier(0, 0, 0.2, 1);
            }
            &:after{
                display: inline-block;
                content:'';
                width:34px;
                height:34px;
                background:url(/assets/img/cmn_icon_toggle-off.svg) top left / cover;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
            }
        }
    }
    &__content{
        border-top: 2px solid #B7C9CB;
    }
    &__inner{
        margin: 20px;
    }
}

.section-table{
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    &__heading{
        background-color: $color-main;
        color:#fff;
        padding:4px;
        text-align: center;
        vertical-align: middle;
        border-right: 2px solid #F4F9FC;
        border-bottom: 2px solid #F4F9FC;
    }
    &__desc{
        padding:20px;
        border-right: 2px solid #F4F9FC;
        border-bottom: 2px solid #F4F9FC;
        &.-short{
            padding: 10px 20px 10px 20px;
        }
    }
}

// guide固有パーツ
// .guide-online
.guide-online{
    border-top: 1px solid #EAEAEA;
    margin-top: 30px;
    &__wrapper{
        display: flex;
    }
    &__left{
        width: 50px;
        margin-right: 15px;
    }
    &__right{        
        border-top: 1px solid #EAEAEA;
        padding-top: 30px;
        width: 100%;
        &.border-none{
            border: none;
        }
    }
    &__tit{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 25px;
    }
    &__desc{
        margin-bottom: 12px;
    }
    &__img{
        width: 50px;
        padding-top: 30px;
    }
    &__line{
        margin: -8px auto 0;
        border: 3px solid #CCDADB;
        width: 3px;
        height: 80%;
        &.-short{
            height: 73%;
        }
    }
    &__triangle{
        margin: -5px auto 0;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 25px solid #CCDADB;
    }
    &__circle{
        margin: -5px auto 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #CCDADB;
    }
}

// guide-step
.guide-step{
    padding: 10px 12px 8px;
    transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    display: block;
    background-color: #F4F9FC;
    border:1px solid #CCDADB;
    border-radius: 4px;
    text-decoration: none;
    width: 30%;
    min-width: 230px;
    &:hover{
        background-color: #DEEDF5;
        transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    }
    &__sticky {
        position: inherit;
        left: 0px;
        width: 100%;
        z-index: 97;
        transition: all .2s cubic-bezier(0, 0, 0.2, 1);
        &.-shrink{
            position: sticky;
            top: 70px;
        }
    }
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 20px;
        padding: 20px 30px;
        background-color: #fff;
    }
    &.-long{
        padding: 8px 10px;

    }
    &.-shrink{
        padding: 2px 10px 1px;
        .-num{
            margin: -7px 0 0 0;
        }
    }
    &.-current{
        background-color: $color-main;
        .guide-step__heading{
            color: #CBD5EE;
        }
        .guide-step__desc{
            color: #fff;
        }
    }
    &__list{
        display: flex;
        align-items: center;
        position: relative;
    }
    &__heading{
        font-size: 13px;
        font-weight: bold;
        color:#707272;
        padding: 0 7px 0 0;
        margin: 0px 10px 0 0;
        border-right: 1px solid #CCDADB;
        text-align: center;
        .-num{
            font-size: 33px;
            display: block;
            line-height: 1;
            border-right: none;
            margin: -2px 0 0 0;
            padding: 0 1px 0 0;
        }
    }
    &__desc{
        color:#444;
        font-weight: bold;
        line-height: 22px;

        & span{
            display: block;
            font-size: 12px;
            margin-left: -10px;
            white-space: nowrap;
            &.-column{
                margin-left: 5px;
            }
        }
        &.-column{
            display: flex;
        }
    }
    &__arrow{
        width: 0;
        height: 0;
        border-left: 15px solid $color-main;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        &.-down{
            border:none;
            width:17px;
            height:30px;
            margin: 0 auto;
            display: block;
        }
    }
    &.-shadow{
        box-shadow: 0 2px 0 #b7c9cb;
    }
}
a.guide-step.-flow{
    width: 100%;
}
a.guide-step.-flow .guide-step__list:after{
    content: '';
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: calc(50% - 6px);
    right: 12px;
    transform: rotate(45deg);
    border-top: 2px solid #444;
    border-right: 2px solid #444;
}


// step-box(guide1)
.step-box{
    padding: 10px 12px 8px;
    transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    display: block;
    background-color: #F4F9FC;
    border:1px solid #CCDADB;
    border-radius: 4px;
    text-decoration: none;
    width: 30%;
    min-width: 230px;
    &:hover{
        background-color: #DEEDF5;
        transition: all .2s cubic-bezier(0, 0, 0.2, 1);
    }
    &.-shadow{
        box-shadow: 0 2px 0 #b7c9cb;
    }
}

a.step-box.-flow{
    width: 100%;
}

.step-box-arrow{
    &::after{
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        display: inline-block;
        position: absolute;
        top: calc(50% - 6px);
        right: 0px;
        transform: rotate(45deg);
        border-top: 2px solid #444;
        border-right: 2px solid #444;
    }
}

// guide-login
.guide-login{
    background-color: #f4f9fc;
    padding: 66px 0 62px;
    margin-top: 30px;
    margin-bottom: 33px;
    &__hedding{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 54px;
    }
    &__wrapper{
        width: 654px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
    }
    &__box{
        text-align: center;
        background-color: #fff;
        border: 1px solid #CCDADB;
        border-radius: 2%;
        width: 300px;
        padding: 30px 30px;
        &:nth-child(3){
            width: 400px;
            margin: 55px auto 0;
            padding: 30px 23px;
            p:last-child{
                text-align: left;
            }
        }
    }
    &__img{
        width: 102px;
        margin: 0 auto 18px;
    }
    &__tit{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &__desc{
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        &:nth-child(4){
            margin: 17px 0 12px;
            text-align: center;
        }
        span{
            width: calc(100% - 16px);
            display: inline-block;
            margin-left: 4px;
            vertical-align: top;
        }
    }
    &__arrow-left{
        position: absolute;
        top: 268.5px;
        left: 50px;
    }
    &__arrow-right{
        position: absolute;
        top: 268.5px;
        right: 50px;
    }
    &__btn{
        text-align: center;
        .button{
            width: 360px;
        }
    }
}

// .guide-request
.guide-request{
    display: flex;
    justify-content: center;
    margin-top: 55px;
    &__wrapper{
        &.left{
            width: 65%;
            border-right: 1px solid #E7E7E7;
            padding-right: 55px;
            @media screen and (max-width: 1000px) { 
                &.left{
                    padding-right: 40px;
                }
            }
            &.second{
                width: 50%;
                padding-right: 40px;
                padding-left: 27px;
            }
        }
        &.right{
            width: 35%;
            padding-left: 55px;
            @media screen and (max-width: 1016px) { 
                &.right{
                    padding-left: 40px;
                }
            }
            &.second{
                width: 50%;
                padding-left: 40px;
                padding-right: 27px;
            }
        }
    }
    &__img{
        width: 150px;
        margin: 30px auto 0;
    }
    &__tit{
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        padding: 23px 0 25px;
    }
    &__desc{
        height: 200px;
    }
    &__btn{
        margin-bottom: 25px;
    }
    &__list{
        height: 120px;
        margin-bottom: 23px;
        .-inner{
            span{
                width: 90%;
                display: inline-block;
                height: 0;
            }
            &::before{
                content:"・";
                vertical-align: top;
            }
        }
    }
    &__info{
        margin-bottom: 30px;
        .tit{
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
}

// guide-movie
.guide-movie{
    width: 49%;
    &__wrapper{
        display: flex;
        justify-content: space-between;
    }
    &__tit{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    &__thumbnail{
        img{
            width:100%;
        }
    }
    &__time{
        font-size: 16px;
        font-weight: normal;
        color: #444444;
        margin-top: 5px;
    }
}

// news
.news{
    &__wrapper{
        margin-bottom: 30px;
    }
    &__tit{
        font-size: 20px;
        font-weight: bold;
        padding: 0 0 16px 10px;
    }
    &__under-line{
        border-bottom: 1px solid #EAEAEA;
    }
}

// news/details.html
.news-details{
    padding: 0 30px 0 30px;
    &__tit{
        font-size: 32px;
        font-weight: normal;
        color: $color-main;
        padding-bottom: 40px;
    }
    &__date{
        .top{
            &__info{
                &__list{
                    padding: 0 0 24px;
                    border-top: none;
                    &__date{
                        font-size: 16px;
                    }
                    .visited{
                        color: #A342C1;
                    }
                }
            }
        }

    }
    &__wrapper{
        border-top: 1px solid #EAEAEA;
        padding: 50px 0 100px;
    }
    &__txt{
        font-size: 16px;
        &.-list{
            display: flex;
        }
    }
}

// faq
.faq{
    &__wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 10px;
        &:last-child{
            border-bottom: none;
        }
    }
    &__inner{
        width: 48.5%;
        padding-bottom: 30px;
    }
    &__btn{
        margin: 0 0 20px;
        display: block;
        height: 60px;
        span {
            font-size: 12px;
            display: block;
            padding-top: 10px;
        }
    }
    &__question{
        .question{
            color: #3157BC;
            font-weight: bold;
            margin-right: 4px;
        }
        .txt-box{
            display: inline-block;
            width: 90%;
            vertical-align: top;
        }
    }
    &__answer{
        display: flex;
        padding: 24px 20px;
        .answer{
            color: #C94701;
            font-weight: bold;
            margin-right: 4px;
        }
    }
    &__toggle{
        margin-top: 20px;
    }
}

.faq-details{
    &__tit{
    margin-bottom: 20px;
    }
}

// contact
.contact{
    &__wrapper{
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 42px;
        margin-bottom: 42px;
        .txt-note{
            margin-bottom: 24px;
        }
        &:nth-child(3){
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 50px;
        }
    }
    &__tit{
        text-align: center;
        font-size: 16px;
        color: #3157BC;
    }
    &__access{
        display: flex;
        justify-content: center;
        text-decoration: none;

        font-size: 34px;
        color: #3157BC;
        margin-bottom: 33px;
        img{
            width: 34px;
            margin-right: 8px;
        }
    }
    &__subtxt{
        display: block;
        margin: 16px 0 0 16px;
    }
} 

//footer
.footer{
    border-top:#E7E7E7 1px solid;
    padding:20px 0;
    text-align: center;
    &__wrapper{
        border-bottom:#E7E7E7 1px solid;
        padding-bottom: 20px;
    }
    &__list{
        font-size: 12px;
        display: inline-block;
        &::after{
            content:"|";
            padding: 0 2px 0 8px;
        }
        &:last-child::after{
            display: none;
        }
    }
    &__logo{
        width:125px;
        height:45px;
        margin:20px auto;
    }
}
.copyright{
    font-size: 12px;
    color:#A7A7A7;
}

// modal
.modaal-gallery-item img{
    max-height: 80vh;
}
.modaal-wrapper .modaal-close{
    background:$color-main;
    width:40px;
    height:40px;
    box-shadow: 0 2px 0 #000e5c;
}
.modaal-close:after, .modaal-close:before {
    top: 12px;
    left: 19.5px;
    width: 2px;
    height: 16px;
    border-radius: 0px;
}
.modaal-close {
    position: absolute;
    right: -20px;
    top: -20px;
}
.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
    background: #fff;
}
.modal-content{
    cursor:pointer;
    &__wrapper{
        padding:30px 25px;
    }
    &__tit{
        font-size: 32px;
        color: #3157BC;
        text-align: center;
        margin-bottom: 30px;

    }
    &__desc{
        margin: 0 auto 40px;
        &.-box{
            width: 640px;
        }
    }
}
.modaal-outer-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.modaal-inner-wrapper{
    padding: 0 !important;
    height: auto;
    max-width: 900px;

}
.modaal-video-wrap{
    margin: 0;
}
.modal-btn__blue{
    padding-top: 30px;
    border: none;
    a{
        width: 220px;
        height: 45px;
        cursor: pointer;
        &::after{
            display: none;
        }
    }
}
.modal-btn__yellow{
    display: block;
    width: 360px;
    margin: 0 auto;
    padding: 0;
    span{
        display: block;
        padding-top: 4px;
    }
    &__wrapper{
        display: flex;
    }
    &.-second{
        height: 75px;
    }
}
.modal__list{
    margin-bottom: 30px;
}

.modal__table{
    width: 100%;
    th{
        padding: 5px 10px;
        border: 1px solid #ccdadb;
        background-color: #3157bc;
        text-align: center;
        color: #fff;
    }
    td{
        padding: 10px;
        border: 1px solid #ccdadb;
        background-color: #f4f9fc;
        text-align: center;
        &.-ok{
            color: #3157bc;
        }
        &.-ng{
            background-color: #fff8f2;
            color: #c94701;
        }
    }
}

@media screen and (min-width:1400px) {
    .modaal-video-container {
    padding-bottom: 56.25%;
    height: auto;
    }
}

.right-btn{
    margin-left: 30px;
    width: auto;
}

// margin-top
.mt0{
    margin-top: 0 !important;
}
.mt16{
    margin-top: 16px !important;
}
.mt20{
    margin-top: 20px !important;
}
.mt30{
    margin-top: 30px !important;
}
.mt40{
    margin-top: 40px !important;
}
.mt50{
    margin-top: 50px !important;
}
.mt64{
    margin-top: 64px !important;
}
.mt100{
    margin-top: 100px !important;
}

// margin-bottom
.mb0{
    margin-bottom: 0 !important;
}
.mb10{
    margin-bottom: 10px!important;
}
.mb20{
    margin-bottom: 20px !important;
}
.mb50{
    margin-bottom: 50px !important;
}
.mb60{
    margin-bottom: 60px !important;
}

// padding
.pb10{
    padding-bottom: 10px !important;
}
.pb50{
    padding-bottom: 50px !important;
}
.pb70{
    padding-bottom: 70px !important;
}
.pb100{
    padding-bottom: 100px !important;
}
.pl0{
    padding-left: 0 !important;
}

::placeholder {
    color: #aaa;
  }
  /* 旧Edge対応 */
  ::-ms-input-placeholder {
    color: #aaa;
  }
  /* IE対応 */
  :-ms-input-placeholder {
    color: #aaa;
  }

  .modaal-close-fix{
    right: unset!important;
    top: unset!important;
    background: unset!important;
    box-shadow: unset!important;
    padding: 10px!important;
    text-decoration: none!important;
    position: relative!important;
    text-align: center!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    color: #3157bc!important;
    flex-wrap: wrap!important;
    border: 1px solid #3157bc!important;
    border-radius: 50px!important;
    width: 220px!important;
    height: 45px!important;
    &::after{
        display: none!important;
    }
    &::before{
        display: none!important;
    }
}